.form__control {
  display: flex;
  flex-direction: column;
  margin: 5px 10px;
  /* align-items:center ; */
  /* justify-content: space-between; */
  text-align: left;
}

.multipleSelect {
  display: block;
  /* width: 300px; */
  height: 150px;
  padding: 6px 12px;
  font-size: 14px;
  columns: #555555;
  line-height: 1.428571;
  background-color: #ffffff;
  border: 1px solid #cccccc;
  background-image: none;
  border-radius: 4px;
}

.multipleSelect {
  display: block;
  width: 96%;
  height: 150px;
  padding: 6px 12px;
  font-size: 14px;
  columns: #555555;
  line-height: 1.428571;
  background-color: #ffffff;
  border: 1px solid #cccccc;
  background-image: none;
  border-radius: 4px;
}

.main__label {
  display: flex;
  /* margin-bottom: 5px; */
  /* font-weight: bold; */
}

.form__control__field__div {
  display: flex;
  justify-content: center;
  align-items: center;
}

.form__control > label {
  /* display: flex; */
  /* margin-bottom: 10px; */
  margin-right: 10px;
  /* font-weight: bold; */
  /* align-items: center; */
}

input[type="email"].input__field {
  display: block;
  width: 96%;
  padding: 6px 12px;
  font-size: 14px;
  columns: #555555;
  line-height: 1.428571;
  background-color: #ffffff;
  border: 1px solid #cccccc;
  background-image: none;
  border-radius: 4px;
}

input[type="tel"].input__field {
  display: block;
  width: 96%;
  padding: 6px 12px;
  font-size: 14px;
  columns: #555555;
  line-height: 1.428571;
  background-color: #ffffff;
  border: 1px solid #cccccc;
  background-image: none;
  border-radius: 4px;
}

input[type="radio"].input__field {
  display: flex;
  width: 30px;
  width: 96%;

  padding: 6px 12px;
  font-size: 14px;
  columns: #555555;
  line-height: 1.428571;
  background-color: #ffffff;
  border: 1px solid #cccccc;
  background-image: none;
  border-radius: 4px;
}

input[type="checkbox"].input__field {
  display: flex;
  width: 30px;
  width: 96%;

  padding: 6px 12px;
  font-size: 14px;
  columns: #555555;
  line-height: 1.428571;
  background-color: #ffffff;
  border: 1px solid #cccccc;
  background-image: none;
  border-radius: 4px;
}

.form__control > textarea {
  display: block;
  width: 96%;
  padding: 6px 12px;
  font-size: 14px;
  columns: #555555;
  line-height: 1.428571;
  background-color: #ffffff;
  border: 1px solid #cccccc;
  background-image: none;
  border-radius: 4px;
}

input[type="text"].input__field,
input[type="number"] {
  display: block;
  width: 96%;
  padding: 6px 12px;
  font-size: 14px;
  columns: #555555;
  line-height: 1.428571;
  background-color: #ffffff;
  border: 1px solid #cccccc;
  background-image: none;
  border-radius: 4px;
}

input[type="number"].input__field1,
input[type="number"].input__field2 {
  width: 40px !important;
}
input[type="number"].input__field {
  display: block;
  width: 96%;
  padding: 6px 12px;
  font-size: 14px;
  columns: #555555;
  line-height: 1.428571;
  background-color: #ffffff;
  border: 1px solid #cccccc;
  background-image: none;
  border-radius: 4px;
  /* Firefox */
  -moz-appearance: "textfield";
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.searchbar__input {
  min-height: 40px;
  background: #fff !important;
  border: 1px solid #ccc !important;
}
.mandateReportView__container {
  background: #fff !important;
  border-radius: 3px;
}
