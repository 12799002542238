.form__control__field__div {
  display: flex;
  justify-content: flex-start;
}

.input__field1 {
  flex: 0.3;
  display: block;
  width: 95%;
  padding: 6px 12px;
  font-size: 14px;
  columns: #555555;
  line-height: 1.428571;
  background-color: #ffffff;
  border: 1px solid #cccccc;
  background-image: none;
  border-radius: 4px;
}

.input__field2 {
  flex: 1;
  display: block;
  width: 95%;
  padding: 6px 12px;
  font-size: 14px;
  columns: #555555;
  line-height: 1.428571;
  background-color: #ffffff;
  border: 1px solid #cccccc;
  background-image: none;
  border-radius: 4px;
  -moz-appearance: "textfield";
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
