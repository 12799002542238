.form__control__checkbox {
    display: flex;
    align-items: center;
    margin: 20px 0px;
}
  
/* .form__control__checkbox > label {
    width: 100px;
    margin-bottom: 5px;
    font-weight: bold;
} */

.form__control__checkbox > p{
    margin-right: 10px;
}

.input__field{
    display: block;
    width: 96%;
    padding: 6px 12px;
    font-size: 14px;
    columns: #555555;
    line-height: 1.428571;
    background-color: #ffffff;
    border: 1px solid #cccccc;
    background-image: none;
    border-radius: 4px;
}